
import { Avatar, Modal, Tag, message, Badge } from 'ant-design-vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { conversationList, conversationMessageList, messageSend } from '@/api/message';
import { defineComponent, ref, reactive } from 'vue';
import storage from 'store';

import MessageWebSocket from '@/util/ws';
dayjs.extend(utc);
dayjs.extend(timezone);

export default defineComponent({
  name: 'orderList',
  components: { AAvatar: Avatar, AModal: Modal, ATag: Tag, ABadge: Badge },
  setup() {
    const tableSource: any = ref([]);
    const tableLoading = ref(false);
    const pagination = reactive({
      current: 1,
      total: 10,
      next: true,
      perv: false,
    });
    const tableColumns = [
      { title: '对话ID', dataIndex: 'objectId', width: '240px', key: 'objectId' },
      { title: '第一次聊天时间', dataIndex: 'createdAt', width: '180px', key: 'created_at' },
      { title: '最新聊天时间', dataIndex: 'updatedAt', width: '180px', key: 'updated_at' },
      { title: '发起用户', key: 'user', width: '160px', slots: { customRender: 'user' } },
      { title: '接受陪玩', key: 'npc', width: '160px', slots: { customRender: 'npc' } },
      { title: '对话名称', key: 'name', width: '180px', slots: { customRender: 'name' } },
      { title: '操作', key: 'action', slots: { customRender: 'action' } },
    ];

    function unreadMessageCount(el: any) {
      const list = storage.get('unreadMessageList', []);
      const currentUnread = list.find((item: any) => item.convId === el.id);
      el['count'] = currentUnread ? currentUnread.count : 0;
      el['createdAt'] = dayjs(el.createdAt).format('YYYY-MM-DD HH:mm:ss');
      el['updatedAt'] = el.lm ? dayjs(el.lm.iso).format('YYYY-MM-DD HH:mm:ss') : '';

      return el;
    }

    function getConversationList() {
      tableLoading.value = true;
      conversationList({ PageIndex: pagination.current }).then((ret) => {
        tableLoading.value = false;
        tableSource.value = ret.data.map(unreadMessageCount);
        pagination.next = ret.data.length < 10 ? false : true;
        pagination.perv = pagination.current === 1 ? false : true;
      });
    }

    function handlePagination(type: string) {
      if (type === 'perv') {
        pagination.current--;
      } else {
        pagination.current++;
      }
      getConversationList();
    }

    const currentConversationInfo: any = ref({});
    const messageList = ref<Array<any>>([]);
    const messageVisible = ref(false);
    const messageParams = reactive({
      next: false,
      BeforeMessageId: '',
      BeforeTime: '',
    });
    function handleMessageRecord(el: any) {
      el.count = 0;
      currentConversationInfo.value = {
        id: el.objectId,
        clientId: el.c,
        npcId: 'um_' + el.npc.Id,
        ['um_' + el.npc.Id]: { ...el.npc, ...{ class: 'message-list-npm', name: '陪玩', tagColor: 'orange' } },
        ['um_' + el.user.Id]: { ...el.user, ...{ class: '', name: '用户', tagColor: '' } },
      };
      messageParams.BeforeMessageId = '';
      messageParams.BeforeTime = '';
      messageParams.next = false;
      messageList.value = [];
      getMessageList();

      const list: Array<any> = storage.get('unreadMessageList', []);
      const currentUnreadIndex = list.findIndex((item: any) => item.convId === el.id);
      if (currentUnreadIndex != -1) {
        list.splice(currentUnreadIndex, 1);
        storage.set('unreadMessageList', list);
      }
    }
    const recordLoading = ref(false);

    function getMessageList() {
      recordLoading.value = true;
      conversationMessageList(currentConversationInfo.value.id, messageParams).then((ret) => {
        recordLoading.value = false;
        messageList.value = [
          ...messageList.value,
          ...ret.data.map((item: any) => {
            try {
              item.data = JSON.parse(item.data);
            } catch (err) {
              console.log(err);
            }
            return item;
          }),
        ];
        messageVisible.value = true;
        messageParams.next = ret.data.length < 10 ? false : true;
      });
    }

    function messageScroll(e: any) {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight && messageParams.next) {
        messageParams.BeforeMessageId = messageList.value[messageList.value.length - 1].message_id;
        messageParams.BeforeTime = messageList.value[messageList.value.length - 1].timestamp;
        getMessageList();
      }
    }

    const sendMessageParams = reactive({
      FromClient: '',
      Message: '',
    });

    function handleSendMessage(searchValue: string) {
      sendMessageParams.Message = searchValue;
      sendMessageParams.FromClient = currentConversationInfo.value.npcId;
      messageSend(currentConversationInfo.value.id, sendMessageParams).then(() => {
        sendMessageParams.Message = '';
        message.success('发送成功');
      });
    }

    function userAvatar(el: any) {
      return currentConversationInfo.value[el.from].Avatar;
    }

    function messageClass(el: any) {
      return currentConversationInfo.value[el.from].class;
    }

    function messageTypes(el: any) {
      return currentConversationInfo.value[el.from];
    }

    function messageDate(el: any) {
      return dayjs(el.timestamp).format('YYYY-MM-DD HH:mm:ss');
    }

    function handleAfterClose() {
      messageVisible.value = false;
    }

    MessageWebSocket.onMessage = function (ret: any) {
      if (messageVisible.value && currentConversationInfo.value.id === ret.data.convId) {
        messageList.value.unshift({
          conversation_id: ret.data.convId,
          data: JSON.parse(ret.data.content),
          from_client: ret.data.fromPeer,
          message_id: ret.data.msgId,
        });
        return;
      }

      const currentUnreadIndex = tableSource.value.findIndex((item: any) => ret.data.convId === item.id);
      if (currentUnreadIndex != -1) {
        tableSource.value[currentUnreadIndex].count++;
        return;
      }

      const list = storage.get('unreadMessageList', []);
      const isSome = list.some((item: any) => item.convId === ret.data.convId);
      if (isSome) {
        list.map((item: any) => {
          if (item.convId === ret.data.convId) {
            item.count++;
          }
          return item;
        });
      } else {
        list.push({
          convId: ret.data.convId,
          count: 1,
        });
      }
      storage.set('unreadMessageList', list);
    };

    getConversationList();
    return { tableSource, tableColumns, recordLoading, messageParams, handlePagination, sendMessageParams, handleSendMessage, messageScroll, handleMessageRecord, messageDate, messageTypes, messageClass, messageVisible, messageList, pagination, handleAfterClose, userAvatar, tableLoading };
  },
});
