import request from './axios';

//对话列表
export const conversationList = (data: any) => {
  return request({
    url: '/leancloud/conversation/query',
    method: 'get',
    params: data,
  });
};

export const conversationMessageList = (conversation_id: any, params: any) => {
  return request({
    url: `/leancloud/conversation/${conversation_id}/message`,
    method: 'post',
    data: params,
  });
};

export const messageSend = (conversation_id: any, params: any) => {
  return request({
    url: `/leancloud/conversation/${conversation_id}/send`,
    method: 'post',
    data: params,
  });
};
