
import { defineComponent, computed, ref, reactive, watch } from 'vue';
import _ from 'lodash';
import upload from '@/components/modules/upload/index.vue';
import { useCategoryTable } from '@/views/npc/category/setup';
import { addTeam, teamDetail, teamUpdate, teamPitUpdate } from '@/api/team';
import { awaitWrap } from '@/util/index';
import { message } from 'ant-design-vue';
import { DatePicker } from 'ant-design-vue';
import Map from '@/components/map/map';

export default defineComponent({
  components: { upload, ADatePicker: DatePicker },
  name: 'teamAdd',
  props: {
    teamId: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:visible', 'handld-save'],
  setup(props: any, { emit }) {
    const aMap = new Map({ plugins: ['AMap.PlaceSearch', 'AMap.Geocoder'] }, 'container');

    const taemInfoModel: any = {
      Id: 0,
      AlreadyCount: '',
      BackgroundImg: '',
      EndTime: '',
      ImgIntroduce: '',
      PlanCount: '',
      SecondCategory: '',
      StartTime: '',
      Summary: '',
      TeamAddress: '',
      Lat: '',
      Lng: '',
      TeamTitle: '',
      Pits: [],
    };

    const taemInfo = reactive(_.clone(taemInfoModel));
    watch(
      () => props.teamId,
      (val) => {
        if (val) {
          getTeamDetail();
        } else {
          _.assignIn(taemInfo, taemInfoModel);
        }
      }
    );
    async function getTeamDetail() {
      const { code, data } = await awaitWrap(teamDetail(props.teamId));
      if (code === 200) {
        data.data.Pits = data.data.Pits.map((item: any) => {
          item['edit'] = true;
          return item;
        });
        _.assignIn(taemInfo, data.data);
      }
    }

    const { tableSource: categorylist } = useCategoryTable();

    const show = computed({
      get() {
        return props.visible;
      },
      set(val) {
        emit('update:visible', val);
      },
    });

    const formRef = ref();

    const imageComputed = computed({
      get() {
        return taemInfo.BackgroundImg ? taemInfo.BackgroundImg.split('|') : [];
      },
      set(val: Array<string>) {
        taemInfo.BackgroundImg = val.join('|');
      },
    });

    const ImgIntroduceComputed = computed({
      get() {
        return taemInfo.ImgIntroduce ? taemInfo.ImgIntroduce.split('|') : [];
      },
      set(val: Array<string>) {
        taemInfo.ImgIntroduce = val.join('|');
      },
    });

    //获取地址信息
    function handleBlurAddress() {
      aMap
        .getLocation(taemInfo.TeamAddress)
        .then((ref: any) => {
          const addr = ref.addressComponent;
          taemInfo.Address = ref.formattedAddress.replace(`${addr.province}${addr.city}${addr.district}`, '');
          taemInfo.Lat = ref.location.lat;
          taemInfo.Lng = ref.location.lng;
        })
        .catch(() => {
          taemInfo.TeamAddress = '';
          message.warning('地址转化失败！请重新填写');
        });
    }

    async function haeldAdd(callback: () => void) {
      if (taemInfo.Id) {
        const ret = await awaitWrap(
          teamUpdate(taemInfo.Id, {
            BackgroundImg: taemInfo.BackgroundImg,
            ImgIntroduce: taemInfo.ImgIntroduce,
            Summary: taemInfo.Summary,
            TeamTitle: taemInfo.TeamTitle,
          })
        );
        if (ret.code === 200) {
          message.success('修改成功');
          emit('handld-save', {});
          show.value = false;
        }
        callback();
      } else {
        const ret = await awaitWrap(addTeam(taemInfo));
        if (ret.code === 200) {
          message.success('添加成功');

          // console.log(im)
          // teamRoomId()
          emit('handld-save', {});
          show.value = false;
        }
        callback();
      }
    }

    function setPits() {
      taemInfo.Pits = [];
      for (let i = 0; i < taemInfo.PlanCount - taemInfo.AlreadyCount; i++) {
        taemInfo.Pits.push({
          PitPrice: '',
          PitTitle: '',
          PitType: '',
        });
      }
    }

    function changePits(item: any) {
      if (!item.MemberId) {
        if (!item.edit) {
          item.edit = true;
          teamPitUpdate(item.Id, {
            PitPrice: item.PitPrice,
            PitTitle: item.PitTitle,
            PitType: item.PitType,
          }).then((ret: any) => {
            if (ret.code === 200) {
              message.success('修改成功');
            }
          });
        } else {
          item.edit = false;
        }
      }
    }

    return { categorylist, taemInfo, show, handleBlurAddress, formRef, imageComputed, ImgIntroduceComputed, haeldAdd, setPits, changePits };
  },
});
