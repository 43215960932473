
import { defineComponent, ref } from 'vue';
import { useTable } from '@/hooks/use-table';
import { useCategoryTable } from '@/views/npc/category/setup';
import { teamList, teamInvalid, teamStart, teamEnd, teamRoomId } from '@/api/team';
import { ModalError } from '@/util/message';
import { awaitWrap } from '@/util/index';
import { message } from 'ant-design-vue';
import teamAdd from './components/add.vue';
import IM from '@/util/IM';
import YImage from '@/components/modules/image/index.vue';

export default defineComponent({
  name: 'orderList',
  components: { teamAdd, YImage },
  setup() {
    const { tableLoading, tableSource, pagination, searchParmas, handlePageChange, getList, handleReset } = useTable({
      parmas: {
        TeamType: '',
        TeamStatus: '',
        SecondCategory: '',
        TeamTitle: '',
        PageSize: 10,
        PageIndex: 1,
      },
      getParmas: teamList,
    });

    const { tableSource: categorylist } = useCategoryTable();

    const teamType = [
      { code: 0, value: '组队中' },
      { code: 1, value: '游戏中' },
      { code: 2, value: '游戏结束' },
      { code: 3, value: '组队作废' },
    ];

    const tableColumns = [
      { title: '发布方', dataIndex: 'PublisherName', key: 'PublisherName' },
      { title: '游戏', dataIndex: 'SecondCategory', key: 'SecondCategory' },
      { title: '类型', dataIndex: 'TeamTypeStr', key: 'TeamTypeStr' },
      { title: '总人数', dataIndex: 'PlanCount', key: 'PlanCount' },
      { title: '参与人数', dataIndex: 'AlreadyCount', key: 'AlreadyCount' },
      { title: '组队信息', dataIndex: 'SecondCategory', width: '300px', key: 'info', slots: { customRender: 'info' } },

      // { title: '开始时间', dataIndex: 'StartTime', key: 'StartTime' },
      // { title: '结束时间', dataIndex: 'EndTime', key: 'EndTime' },
      { title: '状态', dataIndex: 'TeamStatusStr', key: 'TeamStatusStr' },
      { title: '操作', key: 'action', width: '300px', slots: { customRender: 'action' } },
    ];

    function handleInvalid(el: any) {
      ModalError('你确定要作废游戏', async () => {
        const { code } = await awaitWrap(teamInvalid(el.Id));
        return new Promise((resolve, reject) => {
          if (code === 200) {
            message.success('修改成功！');
            getList();
            resolve('');
          } else {
            reject();
          }
        });
      });
    }
    function handleStart(el: any) {
      ModalError('你确定要开始游戏', async () => {
        const { code } = await awaitWrap(teamStart(el.Id));
        return new Promise((resolve, reject) => {
          if (code === 200) {
            message.success('修改成功！');
            getList();
            resolve('');
          } else {
            reject();
          }
        });
      });
    }
    function handleEnd(el: any) {
      ModalError('你确定要结束游戏', async () => {
        const { code } = await awaitWrap(teamEnd(el.Id));
        return new Promise((resolve, reject) => {
          if (code === 200) {
            message.success('修改成功！');
            getList();
            resolve('');
          } else {
            reject();
          }
        });
      });
    }

    const show = ref(false);
    const teamId = ref(0);
    const openShowModel = () => {
      show.value = true;
      teamId.value = 0;
    };

    const handleDetail = (el: any) => {
      show.value = true;
      teamId.value = el.Id;
    };

    async function createRoom(el: any) {
      const { id } = await IM.createChatRoom(el.TeamTitle);
      const { code } = await awaitWrap(teamRoomId(el.Id, id));
      if (code === 200) {
        message.success('创建成功！');
        getList();
      }
    }

    return {
      tableLoading,
      teamType,
      categorylist,
      show,
      teamId,

      handleDetail,
      tableSource,
      tableColumns,
      pagination,
      searchParmas,
      handlePageChange,
      handleReset,
      handleInvalid,
      handleEnd,
      handleStart,
      openShowModel,
      createRoom,
    };
  },
});
